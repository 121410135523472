import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { i18n } from './i18n';
import App from './App.vue'
import { MyAxios } from './helpers/axios';


Vue.use(VueCookies);
Vue.config.productionTip = false;

Vue.use(MyAxios);


new Vue({
  i18n,
  render: h => h(App),
}).$mount('#modal-mount')
