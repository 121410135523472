import Axios from 'axios';

Axios.defaults.baseURL = "/api/v2";
// Axios.defaults.baseURL = "http://localhost:3000/api/v2";
Axios.defaults.timeout = 10000;

Axios.interceptors.response.use(
    function(response){ return response; },
    function(err){
        const o = {
            status: err.response.status,
            data: err.response.data
        }
    
        throw o;
    }
);
function callAxios(config){
    return new Promise(async (res, rej) => {
        try{
            res((await Axios(config)).data);
        }
        catch(err){
            rej(err);
        }
    });
}
export const MyAxios = {
    install(Vue, options) {
        Vue.prototype.$axios = {
            $get(url, config){
                return callAxios({
                    url,
                    config,
                    method: 'get'
                });
            },
            $post(url, data, config){
                return callAxios({
                    method: 'post',
                    url,
                    data,
                    ...config
                })
            },
            $put(url, data, config){
                return callAxios({
                    method: 'put',
                    url,
                    data,
                    ...config
                })
            },
            $patch(url, data, config){
                return callAxios({
                    method: 'patch',
                    url,
                    data,
                    ...config
                })
            },
            $delete(url, data, config){
                return callAxios({
                    method: 'delete',
                    url,
                    data,
                    ...config
                })
            }
        }
    }
}