'use strict';

/**
 * 
 * @param {String} str String do przekonwertowania do formy url-safe
 * @returns String w wersji url-safe
 */
function encodeString(str){

    if(typeof str != "string"){
        throw new Error('[helpers/url-friendler@encodeString] Input must be a string, ' + typeof str + ' given');
    }

    //trim white space
    str = str.trim();

    //all to lowercase
    str = str.toLowerCase();
    
    //polish characters
    str = str.replace(/ą/g, 'a')
    .replace(/ć/g, 'c')
    .replace(/ę/g, 'e')
    .replace(/ł/g, 'l')
    .replace(/ń/g, 'n')
    .replace(/ó/g, 'o')
    .replace(/ś/g, 's')
    .replace(/ż/g, 'z')
    .replace(/ź/g, 'z');

    //german & french characters
    str = str.replace(/ä/g, 'a')
    .replace(/à/g, 'a')
    .replace(/â/g, 'a')
    .replace(/ç/g, 'c')
    .replace(/é/g, 'e')
    .replace(/è/g, 'e')
    .replace(/ê/g, 'e')
    .replace(/ë/g, 'e')
    .replace(/î/g, 'i')
    .replace(/ï/g, 'i')
    .replace(/ÿ/g, 'y')
    .replace(/ö/g, 'o')
    .replace(/ô/g, 'o')
    .replace(/œ/g, 'oe')
    .replace(/ü/g, 'u')
    .replace(/ù/g, 'u')
    .replace(/û/g, 'u')
    .replace(/ß/g, 'ss')
    .replace(/æ/g, 'ae')
    .replace(/ø/g, 'o')
    .replace(/å/g, 'a');
    

    //remove every characters thats not [a-z0-9_-\s]
    str = str.replace(/[^a-z0-9_-\s]/g, '');
    //double spaces to one space
    str = str.replace(/\s+/g, ' ');
    //spaces to '-'
    str = str.replace(/\s/g, '-');
    //multiple '-' in a row to single one
    str = str.replace(/[-]{2,}/g, "-");
    
    return str;
}

export default encodeString;