export default {
    login: {
        heading: "Zaloguj się",
        goto_register: "Nie masz konta? Zarejestruj się!",
        email_placeholder: "jan.kowalski@poczta.pl",
        email_error: "Proszę podać poprawny adres e-mail",
        password_error: "Proszę uzupełnić to pole",
        forgot_password_button: "Nie pamiętam hasła",
        forgot_password_link: "/resetowanie-hasla",
        submit: "Zaloguj się"
    },

    register: {
        heading: "Załóż konto",
        goto_login: "Posiadasz już konto? Zaloguj się!",
        label_email: "Adres e-mail",
        email_placeholder: "jan.kowalski@poczta.pl",
        email_error: "Proszę podać poprawny adres e-mail",
        label_password: "Hasło",
        password_error: "Hasło powinno mieć 8-63 znaków długości i zawierać co najmniej 1 małą literę, 1 wielką literę i 1 cyfrę",
        label_name: "Imię i nazwisko",
        name_error: "Proszę uzupełnić to pole",
        submit: "Załóż konto"
    },

    create_page: {
        heading: "Utwórz stronę",
        label_display_name: "Nazwa oferty",
        display_name_error: "Nazwa oferty powinna mieć 3-127 znaków długości",
        label_name: "Link oferty",
        name_error: "Link oferty powinien mieć 3-127 znaków długości oraz zawierać tylko małe litery, cyfry oraz znaki '-' i '_'",
        submit: "Utwórz ofertę"
    },

    general: {
        login_with_fb: "Kontynuuj z Facebook"
    },

    fb: {
        permissions_denied: "Nie udzielono wystarczających uprawnień, by móc zalogować z Facebook",
        login_cancelled: "Logowanie zostało anulowane przez użytkownika"
    },

    axios: {
        server_error: 'Nieoczekiwany błąd serwera',

        e101: "Błąd ReCaptcha",
        e102: "W Ambasly zostało już zarejestrowane konto na podany adres e-mail",
        
        e401: "Błąd ReCaptcha",
        e402: "Nieprawidłowy email lub hasło",
        e403: "To konto zostało utworzone przy pomocy konta Facebook lub Google, proszę skorzystać z innej metody logowania",
        e404: "To konto nie zostało jeszcze aktywowane",
        e405: "To konto zostało zawieszone"
    }
}