<template>
    <button 
        @click="authenticate" 
        class="fb-button btn btn-secondary btn-lg btn-login"
    >
        <img src="@/assets/imgs/facebook-logo-white.png" alt="">
        {{ $t('general.login_with_fb') }}
        <AmSpinner v-if="loading" />
    </button>
</template>

<script>
import AmSpinner from '@/components/AmSpinner';

export default {
    data(){
        return {
            scopes: "email,public_profile",
            loading: false
        }
    },
    computed: {

    },
    methods: {
        authenticate(){
            if(this.loading) return;
            this.loading = true;
            FB.login(
                res => {
                    if(res.status == 'connected'){
                        const wantedScopes = this.scopes.split(',');
                        const grantedScopes = res.authResponse.grantedScopes.split(',');
                        if(!this.compareArrays(wantedScopes, grantedScopes)){
                            this.$emit('response', {
                                status: false,
                                msg: this.$t('fb.permissions_denied')
                            });
                            this.loading = false;
                            return;
                        }
                        this.$emit('response', {
                            status: true,
                            token: res.authResponse.accessToken,
                            uid: res.authResponse.userID
                        });
                        this.loading = false;
                    }
                    else{
                        this.$emit('response', {
                            status: false,
                            msg: this.$t('fb.login_cancelled')
                        });
                        this.loading = false;
                    }
                },
                {
                    scope: this.scopes,
                    return_scopes: true,
                    auth_type: 'rerequest'
                }
            );
        },
        compareArrays(arr1, arr2){
            // if(arr1.length != arr2.length) return false;
            for(let i = 0; i < arr1.length; i++){
                let foundMatch = false;
                for(let j = 0; j < arr2.length; j++){
                    if(arr1[i] == arr2[j]){
                        foundMatch = true;
                        break;
                    }
                }
                if(!foundMatch){
                    return false;
                }
            }
            return true;
        }
    },
    components: {
        AmSpinner
    },
    props: {
        type: {
            type: String,
            default: 'login'
        }
    }
}
</script>