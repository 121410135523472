<template>
    <div class="am-modal__container">
        <transition name="am-modal" appear>
            <div class="am-modal" v-if="show_modal" key="main">
                <div class="am-modal__wrapper">
                    <button class="am-modal__close" @click="show_modal = false">
                        <img src="@/assets/icons/close.svg" alt=""> 
                    </button>
                    
                    <transition name="am-slide" mode="out-in">
                        <!-- LOGIN -->
                        <div
                            class="am-page"
                            v-if="current_page == 'login'"
                            key="login"
                        >
                            <h2 class="login-title text-center pb-2 mb-2 font-s-3">
                                {{ $t('login.heading') }}
                            </h2>
                            <div class="am-modal__scrollable">
                                <div class="mb-3 am-alert am-alert--error" v-if="login_error != ''">
                                    <div class="am-alert__icon">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
                                            <g>
                                                <path d="M165,0C120.926,0,79.492,17.163,48.328,48.327c-64.334,64.333-64.334,169.011-0.002,233.345
                                                    C79.49,312.837,120.926,330,165,330c44.072,0,85.508-17.163,116.672-48.328c64.334-64.334,64.334-169.012,0-233.345
                                                    C250.508,17.163,209.072,0,165,0z M239.246,239.245c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
                                                    L165,186.213l-53.033,53.033c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
                                                    c-5.859-5.857-5.859-15.355,0-21.213L143.787,165l-53.033-53.033c-5.859-5.857-5.859-15.355,0-21.213
                                                    c5.857-5.857,15.355-5.857,21.213,0L165,143.787l53.031-53.033c5.857-5.857,15.355-5.857,21.213,0
                                                    c5.859,5.857,5.859,15.355,0,21.213L186.213,165l53.033,53.032C245.104,223.89,245.104,233.388,239.246,239.245z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="am-alert__text">
                                        {{ login_error }}
                                    </div>
                                </div>

                                <div class="mb-3 am-alert am-alert--info">
                                    <div class="am-alert__text am-alert__text--sm">
                                        Hasła do kont założonych przed aktualizacją z dnia 06.08.2021 zostały zdezaktywowane. Proszę skorzystać z funkcji 'Nie pamiętam hasła' i ustawić nowe hasło dostępowe.
                                    </div>
                                </div>

                                <div class="form">
                                    <form @submit.prevent="onLogin">
                                        <div class="input-icon">
                                            <input 
                                                class="input input-primary" v-model="login_email" 
                                                name="email" 
                                                type="email" 
                                                :placeholder="$t('login.email_placeholder')"
                                            />
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary" v-if="login_email_error"
                                                >
                                                    {{ $t('login.email_error') }}
                                                </div>
                                            </transition>
                                        </div>
                                        <div class="input-icon">
                                            <input 
                                                class="input input-primary" name="password" 
                                                type="password" 
                                                v-model="login_password"
                                                placeholder="••••••••••••••"
                                            />
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary"
                                                    v-if="login_password_error"
                                                >
                                                    {{ $t('login.password_error') }}
                                                </div>
                                            </transition>
                                        </div>

                                        <div class="flex check-password between vcenter mb-1">
                                            <div></div>
                                            <a
                                                :href="$t('login.forgot_password_link')"
                                                class="text-center forgot-link"
                                            >
                                                {{ $t('login.forgot_password_button') }}
                                            </a>
                                        </div>

                                        <div class="">
                                            <div class="text-center">
                                                <button 
                                                    type="submit" 
                                                    class="btn btn-secondary btn-lg btn-login mt-2"
                                                >   
                                                    {{ $t('login.submit') }}
                                                    <AmSpinner 
                                                        v-if="login_loading || fb_loading"
                                                    />
                                                </button>
                                            </div>
                                            <div class="text-center" style="padding-top: 15px;" v-if="!fb_loading">
                                                <FbButton @response="onFbLogin" @click.native.prevent />
                                            </div>
                                        </div>

                                    </form>

                                    <div class="flex center">
                                        <a href="" @click.prevent="current_page = 'register'" class="mt-4">
                                            {{ $t('login.goto_register') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- REGISTER -->
                        <div
                            class="am-page"
                            v-else-if="current_page == 'register'"
                            key="register"
                        >
                            <h2 class="login-title text-center pb-2 mb-2 font-s-3">
                                {{ $t('register.heading') }}
                            </h2>
                            <div class="am-modal__scrollable">
                                <div class="mb-3 am-alert am-alert--error" v-if="register_error != ''">
                                    <div class="am-alert__icon">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
                                            <g>
                                                <path d="M165,0C120.926,0,79.492,17.163,48.328,48.327c-64.334,64.333-64.334,169.011-0.002,233.345
                                                    C79.49,312.837,120.926,330,165,330c44.072,0,85.508-17.163,116.672-48.328c64.334-64.334,64.334-169.012,0-233.345
                                                    C250.508,17.163,209.072,0,165,0z M239.246,239.245c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
                                                    L165,186.213l-53.033,53.033c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
                                                    c-5.859-5.857-5.859-15.355,0-21.213L143.787,165l-53.033-53.033c-5.859-5.857-5.859-15.355,0-21.213
                                                    c5.857-5.857,15.355-5.857,21.213,0L165,143.787l53.031-53.033c5.857-5.857,15.355-5.857,21.213,0
                                                    c5.859,5.857,5.859,15.355,0,21.213L186.213,165l53.033,53.032C245.104,223.89,245.104,233.388,239.246,239.245z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="am-alert__text">
                                        {{ register_error }}
                                    </div>
                                </div>

                                <div class="form">
                                    <form @submit.prevent="onRegister">
                                        <div class="input-icon">
                                            <label for="email">
                                                {{ $t('register.label_email') }}
                                            </label>
                                            <input 
                                                class="input input-primary" v-model="register_email" 
                                                name="email" 
                                                type="email" 
                                                :placeholder="$t('register.email_placeholder')"
                                            />
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary" v-if="register_email_error"
                                                >
                                                    {{ $t('register.email_error') }}
                                                </div>
                                            </transition>
                                        </div>
                                        <div class="input-icon">
                                            <label for="password">
                                                {{ $t('register.label_password') }}
                                            </label>
                                            <input 
                                                class="input input-primary" name="password" 
                                                type="password" 
                                                v-model="register_password"
                                                placeholder="••••••••••••••"
                                            />
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary"
                                                    v-if="register_password_error"
                                                >
                                                    {{ $t('register.password_error') }}
                                                </div>
                                            </transition>
                                        </div>
                                        <div class="input-icon">
                                            <label for="name">
                                                {{ $t('register.label_name') }}
                                            </label>
                                            <input 
                                                class="input input-primary" name="name" 
                                                type="text" 
                                                v-model="register_name"
                                            />
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary"
                                                    v-if="register_name_error"
                                                >
                                                    {{ $t('register.name_error') }}
                                                </div>
                                            </transition>
                                        </div>

                                       <p>
                                            <label for="reg">
                                                <input type="checkbox" name="reg" id="reg" v-model="register_terms">&nbsp;<span>Akceptuję <a class="text-secondary" style="text-decoration: underline; cursor: pointer" href="/regulamin" target="_blank">regulamin</a> serwisu</span>
                                            </label>
                                            <transition name="fade">
                                                <div class="text-primary mb-4" v-if="register_terms_error">
                                                    Proszę przeczytać i zaakceptować regulamin serwisu
                                                </div>
                                            </transition>
                                        </p>
                                        <p>
                                            <label for="rodo">
                                                <input type="checkbox" name="rodo" id="rodo" v-model="register_rodo"> Wyrażam zgodę na przetwarzenie moich danych osobowych przez Ambasly. <a class="text-secondary" style="text-decoration: underline; cursor: pointer" @click.prevent="register_show_detailed_rodo = !register_show_detailed_rodo">
                                                    Więcej
                                                </a>
                                                <transition name="fade">
                                                    <div class="rodo-g" v-if="register_show_detailed_rodo">
                                                        Wyrażasz zgodę na przetwarzanie Twoich danych osobowych w celu kontaktu oraz w celu wyświetlania ich potencjalnym klientom. Administratorem danych osobowych jest Galactic Sp. z o.o., ul. Klasztorna 26/2, 61-779 Poznań, NIP: 7821821111. Więcej w <a class="text-secondary" style="text-decoration: underline; cursor: pointer" href="/polityka-prywatnosci" target="_blank">polityce prywatności</a> .
                                                    </div>
                                                </transition>
                                            </label>
                                            <transition name="fade">
                                                <div class="text-primary" v-if="register_rodo_error">
                                                    Zgoda na przetwarzanie danych osobowych jest dobrowolna, lecz wymagana do założenia konta w serwisie
                                                </div>
                                            </transition>
                                        </p>

                                        <div class="">
                                            <div class="text-center">
                                                <button 
                                                    type="submit" 
                                                    class="btn btn-secondary btn-lg btn-login mt-2"
                                                >   
                                                    {{ $t('register.submit') }}
                                                    <AmSpinner 
                                                        v-if="register_loading || fb_loading"
                                                    />
                                                </button>
                                            </div>
                                            <div class="text-center" style="padding-top: 15px;" v-if="!fb_loading">
                                                <FbButton @response="onFbLogin" @click.native.prevent />
                                            </div>
                                        </div>

                                    </form>

                                    <div class="flex center">
                                        <a href="" @click.prevent="current_page = 'login'" class="mt-4">
                                            {{ $t('register.goto_login') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- STWÓRZ STRONĘ -->
                        <div
                            class="am-page"
                            v-else-if="current_page == 'create-page'"
                            key="create-page"
                        >
                            <h2 class="login-title text-center pb-2 mb-2 font-s-3">
                                {{ $t('create_page.heading') }}
                            </h2>
                            <div class="am-modal__scrollable">
                                <div class="form">
                                    <form @submit.prevent="onCreatePage">
                                        <div class="input-icon">
                                            <label for="name">
                                                {{ $t('create_page.label_display_name') }}
                                            </label>
                                            <input 
                                                class="input input-primary" name="name" 
                                                type="text" 
                                                v-model="create_page_display_name"
                                                @input="v => {
                                                    onDisplayNameInput(v.target.value);
                                                }"
                                            />
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary"
                                                    v-if="create_page_display_name_error"
                                                >
                                                    {{ $t('create_page.display_name_error') }}
                                                </div>
                                            </transition>
                                        </div>

                                        <div class="input-icon">
                                            <label for="name">
                                                {{ $t('create_page.label_name') }}
                                            </label>
                                            <div class="am-si">
                                                <div class="am-si__prefix">
                                                    ambasly.com/
                                                </div>
                                                <input 
                                                    class="input input-primary" name="name" 
                                                    type="text" 
                                                    v-model="create_page_name"
                                                    @input="v => {
                                                        create_page_name_modified = true;
                                                    }"
                                                />
                                            </div>
                                            <transition name="fade">
                                                <div 
                                                    class="input-error-msg text-primary"
                                                    v-if="create_page_name_error"
                                                    style="margin-top: 3px !important"
                                                >
                                                    {{ $t('create_page.name_error') }}
                                                </div>
                                            </transition>
                                        </div>

                                        <div class="pb-2 pt-1 text-success" v-if="create_page_name_available && create_page_name_checked">
                                            Link dostępny!
                                        </div>
                                        <div class="pb-2 pt-1 text-danger" v-if="!create_page_name_available && create_page_name_checked">
                                            Link niedostępny
                                        </div>

                                        <div class="">
                                            <div class="text-center pb-1">
                                                <button 
                                                    type="submit" 
                                                    class="btn btn-secondary btn-lg btn-login mt-2"
                                                >   
                                                    {{ $t('create_page.submit') }}
                                                    <AmSpinner 
                                                        v-if="create_page_loading"
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- GOTOWE -->
                        <div
                            class="am-page"
                            v-else-if="current_page == 'success'"
                            key="success"
                        >
                            <div class="am-modal__scrollable">
                                <div class="am-success__icon">
                                    <svg class="am-success__check" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" xml:space="preserve">
                                        <circle 
                                            class="am-success__circle" 
                                            :class="{
                                                'am-success__circle--ready': success_animation_ended,
                                                'am-success__circle--progress': !success_animation_ended
                                            }" 
                                            cx="50" 
                                            cy="50" 
                                            r="46" 
                                            fill="transparent" 
                                        />
                                        <polyline 
                                            class="am-success__tick" 
                                            :class="{
                                                'am-success__tick--ready': success_animation_ended,
                                                'am-success__tick--progress': !success_animation_ended
                                            }" 
                                            points="25,55 45,70 75,33" fill="transparent" />
                                    </svg>
                                </div>

                                <div class="text-center pt-4 pb-1">
                                    <p>
                                        Wszystko gotowe! Twoja strona została utworzona. <br>
                                        Przez 14 dni możesz korzystać za darmo ze wszystkich funkcjonalności Ambasly.
                                    </p>
                                    <a 
                                        type="submit" 
                                        class="btn btn-secondary btn-lg btn-login mt-2"
                                        href="/dashboard"
                                    >   
                                        Edytuj swoją stronę
                                    </a>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>

        <VueRecaptcha 
            loadRecaptchaScript
            :sitekey="recaptcha_public_key"
            size="invisible"
            ref="recaptcha"
            @verify="onVerify"
        />
    </div>
</template>

<script>
import AmSpinner from './components/AmSpinner.vue';
import FbButton from './components/FbButton.vue'; 
import VueRecaptcha from 'vue-recaptcha';
import urlFriendler from './helpers/url-friendler';

export default {

    components: {
        AmSpinner,
        FbButton,
        VueRecaptcha
    },
    
    data(){
        return {
            show_modal: false,

            PAGES: [
                'login',
                'register',
                'create-page',
                'success'
            ],
            current_page: 'login',

            login_email: '',
            login_email_error: false,
            login_password: '',
            login_password_error: false,
            login_loading: false,
            login_error: '',

            register_email: '',
            register_email_error: false,
            register_password: '',
            register_password_error: false,
            register_name: '',
            register_name_error: false,
            register_terms: false,
            register_terms_error: false,
            register_rodo: false,
            register_rodo_error: false,
            register_show_detailed_rodo: false,
            register_loading: false,
            register_error: '',

            create_page_display_name: '',
            create_page_display_name_error: false,
            create_page_name: '',
            create_page_name_error: false,
            create_page_loading: false,
            create_page_name_modified: false,
            create_page_name_checking: false,
            create_page_name_checked: false,
            create_page_name_check_debouncer: null,
            create_page_name_available: false,

            success_animation_ended: false,

            recaptcha_public_key: "6Lfeba4bAAAAAGKnu8HP2AldYw6czTR5p0OnKdnA",

            fb_loading: false,
            captcha_token: null,

            REGEX_EMAIL: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

            auth_token: null //miejsce na token użytkownika po zalogowaniu
        }
    },

    methods: {
        onVerify(token){ this.captcha_token = token; },
        executeCaptcha(){
            return new Promise((resolve, reject) => {
                this.$refs['recaptcha'].execute();

                const checkToken = () => {
                    // console.log('chekin token')
                    if(this.captcha_token !== null){
                        resolve(this.captcha_token);
                        this.captcha_token = null;
                        this.$refs['recaptcha'].reset();
                        return;
                    }

                    setTimeout(checkToken, 250);
                }
                checkToken();
            });
        },
        async onLogin(){
            if(this.login_loading) return;

            this.login_email_error = false;
            this.login_password_error = false;
            this.login_error = '';

            let err = false;
            if(
                this.login_email == ''
                || !this.REGEX_EMAIL.test(this.login_email)
            ){
                err = true;
                this.login_email_error = true;
            }
            if(
                this.login_password == ''
                || this.login_password.length < 8
                || this.login_password.length > 63
            ){
                err = true;
                this.login_password_error = true;
            }

            if(err) return;

            this.login_loading = true;

            try{
                await this.doLogin(this.login_email, this.login_password);
            
                window.location.href = '/dashboard';
            }
            catch(err){ console.error(err); }

            this.login_loading = false;
        },
        async onRegister(){
            if(this.register_loading) return;
            
            //walidacja formularza
            this.register_email_error = false;
            this.register_password_error = false;
            this.register_name_error = false;
            this.register_terms_error = false;
            this.register_rodo_error = false;

            let err = false;
            if(this.register_email == '' || !this.REGEX_EMAIL.test(this.register_email)){
                this.register_email_error = true;
                err = true;
            }
            if(
                this.register_password == ''
                || !this.PASSWORD_VALIDATOR(this.register_password)
            ){
                this.register_password_error = true;
                err = true;
            }
            if(
                this.register_name == ''
                || this.register_name.length > 63
                || this.register_name.length < 3
            ){
                this.register_name_error = true;
                err = true;
            }
            if(this.register_terms !== true){
                this.register_terms_error = true;
                err = true;
            }
            if(this.register_rodo !== true){
                this.register_rodo_error = true;
                err = true;
            }

            if(err) return;

            this.register_loading = true;
            this.register_error = '';

            try{

                let refcode = undefined;
                if(this.$cookies.get('ambasly_partner')){
                    refcode = this.$cookies.get('ambasly_partner');
                    this.$cookies.remove('ambasly_partner');
                }

                await this.$axios.$post('/auth/register', {
                    email: this.register_email,
                    password: this.register_password,
                    name: this.register_name,
                    captcha: await this.executeCaptcha(),
                    referrer_code: refcode
                });

                //automatyczne logowanie gościa - zdublowana funkcjonalność z dashboardem
                await this.doLogin(this.register_email, this.register_password);

                this.register_name = '';
                this.register_email = '';
                this.register_password = '';
                this.register_rodo = false;
                this.register_terms = false;

                this.current_page = 'create-page';

            }
            catch(err){
                if(err.status == 400){
                    if(this.$te('axios.e' + err.data.error_code)){
                        this.register_error = this.$t('axios.e' + err.data.error_code);
                    }
                    else{
                        this.register_error = this.$t('axios.server_error');
                    }
                }
                else{
                    this.register_error = this.$t('axios.server_error');
                }
                console.error(err);
            }

            this.register_loading = false;
        },
        async onCreatePage(){
            if(this.create_page_loading) return;

            this.create_page_display_name_error = false;
            this.create_page_name_error = false;

            let err = false;
            if(
                this.create_page_name == ''
                || this.create_page_name.length < 3
                || this.create_page_name.length > 127
            ){
                err = true;
                this.create_page_name_error = true;
            }
            if(
                this.create_page_display_name == ''
                || this.create_page_display_name.length < 3
                || this.create_page_display_name.length > 127
            ){
                err = true;
                this.create_page_display_name_error = true;
            }
            if(!this.create_page_name_checked) err = true;
            if(err) return;

            this.create_page_loading = true;
            
            try{

                await this.$axios.$post(
                    "/offers", 
                    {
                        name: this.create_page_name,
                        display_name: this.create_page_display_name
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + this.auth_token 
                        }
                    }
                );

                this.current_page = 'success';
            }
            catch(err){
                console.error(err);
            }

            this.create_page_loading = false;
        },
        async onFbLogin(res){
            this.login_error = '';
            this.register_error = '';

            // console.log(res)

            if(res.status == false){
                if(res.msg){
                    this.login_error = res.msg;
                    this.register_error = res.msg;
                }
                return;
            }

            if(this.fb_loading) return;
            this.fb_loading = true;

            try{

                let refcode = undefined;
                if(this.$cookies.get('ambasly_partner')){
                    refcode = this.$cookies.get('ambasly_partner');
                    this.$cookies.remove('ambasly_partner');
                }

                const r = await this.$axios.$post("/auth/facebook", {
                    token: res.token,
                    captcha: await this.executeCaptcha(),
                    referrer_code: refcode
                });

                this.setAuthData(r.data);

                if(r.newly_created){
                    this.current_page = 'create-page';
                }
                else{
                    window.location.href = '/dashboard';
                }

            }
            catch(err) { console.error(err) }
            
            this.fb_loading = false;
        },
        doLogin(email, password){
            return new Promise(async (resolve, reject) => {
                try{

                    const r = await this.$axios.$post("/auth/login", {
                        email,
                        password,
                        captcha: await this.executeCaptcha()
                    });

                    this.setAuthData(r.data);

                    resolve(true);
                }
                catch(err){
                    if(err.status == 400){
                        if(this.$te('axios.e' + err.data.error_code)){
                            this.login_error = this.$t('axios.e' + err.data.error_code);
                            this.register_error = this.$t('axios.e' + err.data.error_code);
                        }
                        else{
                            this.register_error = this.$t('axios.server_error');
                            this.login_error = this.$t('axios.server_error');
                        }
                    }
                    else{
                        this.register_error = this.$t('axios.server_error');
                        this.login_error = this.$t('axios.server_error');
                    }
                    reject(err);
                }
            });
        },
        setAuthData(data){
            this.auth_token = data.token; //jest ważny 1h więc wystarczy na następny etap
            //zapisanie danych w cookie, żeby dashboard już nie wymuszał znowu logowania
            this.$cookies.set(
                'auth',
                data,
                Math.round(data.refresh_token_exp_date / 1000 - Date.now() / 1000),
                '/'
            );
        },

        onDisplayNameInput(v){
            if(!this.create_page_name_modified){
                this.create_page_name = urlFriendler(v);
            }
        },
        debouncePageNameCheck(){
            if(this.create_page_name_check_debouncer != null) clearTimeout(this.create_page_name_check_debouncer);
            this.create_page_name_check_debouncer = setTimeout(this.checkPageName, 500);
        },
        async checkPageName(){
            if(this.create_page_name_checking || this.create_page_name.length < 3) return;
            this.create_page_name_checking = true;

            try{

                const r = await this.$axios.$get("/offers/check-name?name=" + this.create_page_name);

                this.create_page_name_checked = true;
                this.create_page_name_available = r.name_available;

            }
            catch(err){
                console.error(err);
            }

            this.create_page_name_checking = false;
            this.create_page_name_check_debouncer = null;
        },

        PASSWORD_VALIDATOR(password){
            let valid = true;
            if(password.length < 8) valid = false;
            else if(password.length > 63) valid = false;
            else if(!(/[a-ząćęłńóśżźàâæçéèêëîïôœùûüÿöäß]+/).test(password)) valid = false;
            else if(!(/[A-ZĄĆĘŁŃÓŚŻŹÀÂÆÇÉÈÊËÎÏÔŒÙÛÜŸÖÄẞ]+/).test(password)) valid = false;
            else if(!(/[0-9]+/).test(password)) valid = false;
            return valid;
        }
    },

    watch: {
        current_page(nv){
            if(nv == 'success'){
                setTimeout(_ => this.success_animation_ended = true, 3000);
            }
            else{
                this.success_animation_ended = false;

                const auth_cookie = this.$cookies.get('auth');

                if(nv == 'create-page'){
                    const c = this.$cookies.get('offer_name');
                    if(c && typeof c == 'string'){
                        this.create_page_name = urlFriendler(c);
                        this.create_page_display_name = c;
                        this.$cookies.remove('offer_name');
                    }
                }
                else if(nv == 'register' && auth_cookie){
                    window.location.href = "/dashboard/offers/new";
                }
                else if(nv == 'login' && auth_cookie){
                    window.location.href = "/dashboard";
                }
            }
        },

        create_page_name(nv){
            if(nv.length >= 3) this.debouncePageNameCheck();
        }
    },

    created(){
        //modal jest wyzwalany przez zewnątrzne przyciski niebędące częścią tej apki
        const triggers = document.querySelectorAll('.main-modal-trigger');
        // console.log(triggers);
        for(var i = 0; i < triggers.length; i++){
            triggers[i].addEventListener('click', (e) => {
                const t = e.target.getAttribute('data-page');
                if(t && this.PAGES.indexOf(t) !== -1) this.current_page = t;
                this.show_modal = true;
            });
        }

        //montowanie FB SDK
        const fbsdks1 = document.createElement('script');
        fbsdks1.innerHTML = `
            window.fbAsyncInit = function() {
                FB.init({
                    appId: '2733463206930408',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v11.0'
                });
            };
        `;
        document.head.appendChild(fbsdks1);

        const fbsdks2 = document.createElement('script');
        fbsdks2.setAttribute('async', 'async');
        fbsdks2.setAttribute('defer', 'defer');
        fbsdks2.setAttribute('crossorigin', 'anonymous');
        fbsdks2.setAttribute('src', 'https://connect.facebook.net/pl_PL/sdk.js');
        document.head.appendChild(fbsdks2);

        //auto włączenie on mount jeżeli istnieje cookie z odpowiednią komendą
        const amc = this.$cookies.get('auth_modal');
        if(amc && typeof amc == 'object' && amc.cmd == 'show'){
            if(amc.page && this.PAGES.indexOf(amc.page) !== -1) this.current_page = amc.page;
            if(amc.error && amc.error != '') this.login_error = amc.error;
            this.show_modal = true;
            
            this.$cookies.remove('auth_modal', '/');
        }
    }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>